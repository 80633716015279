import { FC, useState } from 'react';
import { ProviderValueItemAuctioneerProps } from './props';
import Wrapper from '../../../Common/wrapper';
import ApiService from '../../../../services/api';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { ProviderValueAction } from '../../../../Interfaces/providerValues';
import { pad } from '../../../../helpers/pad';
import { Wrapped, ResetIcon, LinkButton } from './styled';
import Badge from '../../../Common/Badge';
import ProviderValuesPopoverInfo from 'Components/ProviderValues/components/ProviderValuesPopoverInfo';
import { useTranslation } from 'react-i18next';
import { isDeclinedProviderValue } from '../../../../helpers/declined';
import { StageLot } from 'Interfaces/stageLot';
import HelpText from 'Components/HelpText';
import ModalDeclassifyWinner from 'Components/AuctionLots/components/ModalDeclassifyWinner';

const ProviderValueItemAuctioneer: FC<ProviderValueItemAuctioneerProps> = ({
    providerValue,
    index,
    auctionNoticeId,
    auctionNotice,
    auctionLot,
}) => {
    const { t } = useTranslation();

    const [selectWinnerLoading, setSelectWinnerLoading] = useState(false);

    const getCompanyType = () => {
        let data: { short: string; title: string; color: string };

        if (providerValue.itsSimpleCompany === 1) {
            data = { short: 'ME', title: 'ME/EPP/COOP', color: 'var(--platform-success-color)' };
        } else {
            data = { short: 'OE', title: t('term.not-simple.company'), color: 'var(--platform-success-color)' };
        }

        if (providerValue.itsRegionalCompany === 1 || providerValue.itsRegionalCompany === 2) {
            data = {
                ...data,
                color: 'var(--platform-info-color)',
                title: `${data.title} - ${t('info.regional')}`,
            };
        }

        return data;
    };

    const callProvider = async () =>
        await ApiService.callProvider(auctionNoticeId, providerValue.lotId);

    const getActionByStatus = () => {
        switch (providerValue.currentAction) {
            case ProviderValueAction.show_convoke:
                return {
                    action: () => callProvider(),
                    title: t('info.provider-value.status.convoke.title', {
                        value: pad(providerValue.providerAuctionCode),
                    }),
                    actionName: t('info.provider-value.status.convoke.action'),
                    short: t('info.provider-value.status.convoke.short'),
                };
            case ProviderValueAction.show_winner:
                return {
                    title: t('info.provider-value.status.winner.title'),
                    actionName: t('info.provider-value.status.winner.action'),
                    short: t('info.provider-value.status.winner.short'),
                };
            case ProviderValueAction.show_not_covered:
                return {
                    title: t('info.provider-value.status.not-covered.title'),
                    actionName: t('info.provider-value.status.not-covered.action'),
                    bgColor: 'var(--platform-warning-color)',
                    short: t('info.provider-value.status.not-covered.short'),
                };
            case ProviderValueAction.show_convoked:
                return {
                    title: t('info.provider-value.status.convoked.title'),
                    actionName: t('info.provider-value.status.convoked.action'),
                    short: t('info.provider-value.status.convoked.short'),
                };
            case ProviderValueAction.show_negotiating:
                return {
                    title: t('info.provider-value.status.negotiating.title'),
                    actionName: t('info.provider-value.status.negotiating.action'),
                    bgColor: 'var(--platform-tertiary-color)',
                    short: t('info.provider-value.status.negotiating.short'),
                };
            case ProviderValueAction.show_unclassified:
                return {
                    title: t('info.provider-value.status.unclassified.title'),
                    actionName: t('info.provider-value.status.unclassified.action'),
                    bgColor: '#881c2a',
                    short: t('info.provider-value.status.unclassified.short'),
                };
            case ProviderValueAction.show_disabled:
                return {
                    title: t('info.provider-value.status.disabled.title'),
                    actionName: t('info.provider-value.status.disabled.action'),
                    bgColor: '#d22840',
                    short: t('info.provider-value.status.disabled.short'),
                };
        }
    };

    const resetProviderValue = async () => {
        await ApiService.resetProviderValue({
            auctionNoticeId,
            lotId: auctionLot.id,
            providerValueId: providerValue.id,
        });
    };

    const selectWinnerAndDeclassifyOthersProviders = async (justification: string) => {
        try {
            setSelectWinnerLoading(true);
            await ApiService.selectWinnerAndDeclassifyOthers({
                auctionNoticeId,
                auctionLotId: auctionLot.id,
                providerValueId: providerValue.id,
                justification
            });
        } finally {
            setSelectWinnerLoading(false);
        }
    };

    const undoWinnerUnclassifiedProviderValue = async () => {
        await ApiService.undoDesclassifyProviderValue({
            auctionNoticeId,
            lotId: auctionLot.id,
            providerValueId: providerValue.id,
        });
    };

    const providerValueStatus = getActionByStatus();
    const companyType = getCompanyType();
    const showResetProviderValue =
        providerValue.currentAction === ProviderValueAction.show_negotiating &&
        providerValue.value !== providerValue.originalValue;

    function getFormattedValue(value: string | number | null) {
        return formatValueWithAuctionRules(
            value,
            auctionNotice.judgmentCriterion,
            auctionNotice.rateTypeBid,
            auctionNotice.decimalPlaces
        ).formatted;
    }

    const enableSelectWinner =
        auctionLot?.stage === StageLot.negotiation &&
        !providerValueStatus &&
        !isDeclinedProviderValue(providerValue);

    return (
        <Wrapped
            flexBox
            width='100%'
            height='45px'
            padding='6px 5px'
            margin='4px 0'
            overflowY='hidden'
        >
            <Wrapper justifyContent='space-between' width='100%' alignItems='center' flexBox>
                <Wrapper alignItems='center' flexBox>
                    <Wrapper alignItems='center' flexBox>
                        <Wrapper width='20px' margin='0 5px 0 0' fontSize='12px' fontWeight='700'>
                            {`${index < 9 ? `0${index + 1}` : `${index + 1}`}º`}
                        </Wrapper>
                        <Badge
                            color={companyType.color}
                            title={companyType.title}
                            width='24px'
                            margin='0 2px'
                        >
                            {companyType.short}
                        </Badge>
                    </Wrapper>
                    <ProviderValuesPopoverInfo providerValue={providerValue}>
                        <Wrapper cursor='help' truncate fontSize='13px' margin=' 0 0 0 5px'>
                            {getFormattedValue(providerValue?.value)}
                        </Wrapper>
                    </ProviderValuesPopoverInfo>
                    {!!providerValue?.proposal?.additionalExpense && (
                        <HelpText
                            size={12}
                            helpText={t('info.lot-proposal-values', {
                                totalValue: getFormattedValue(providerValue?.value ?? ''),
                                baseValue: getFormattedValue(
                                    Number(providerValue?.value ?? 0) -
                                        Number(providerValue?.proposal?.additionalExpense ?? 0)
                                ),
                                additionalExpense: getFormattedValue(
                                    providerValue?.proposal?.additionalExpense ?? ''
                                ),
                            })}
                        />
                    )}
                </Wrapper>
                <Wrapper alignItems='center' flexBox>
                    {enableSelectWinner && (
                        <ModalDeclassifyWinner
                            onConfirm={selectWinnerAndDeclassifyOthersProviders}
                            actionEvent='onClick'
                            actionButton={
                                <LinkButton loading={selectWinnerLoading}>
                                    {t('term.negotiate')}
                                </LinkButton>
                            }
                            lotItem={auctionLot?.item}
                        />
                    )}
                    {providerValue.providerAuctionCode && (
                        <Badge
                            title={`${t('info.provider.bid')} ${pad(
                                providerValue.providerAuctionCode
                            )}`}
                            color='var(--platform-primary-color)'
                            width='24px'
                            margin='0 3px'
                        >
                            {`F${pad(providerValue.providerAuctionCode)}`}
                        </Badge>
                    )}
                    {!!providerValueStatus && (
                        <Badge
                            pulse={providerValue.currentAction === ProviderValueAction.show_convoke}
                            cursor='pointer'
                            padding='0 2px'
                            minWidth='27px'
                            color={providerValueStatus.bgColor || 'var(--platform-secondary-color)'}
                            title={providerValueStatus?.title}
                            fontSize='9px'
                            onClick={providerValueStatus?.action}
                        >
                            {providerValueStatus?.short}
                        </Badge>
                    )}
                    {isDeclinedProviderValue(providerValue) && (
                        <Badge
                            title={t('info.provider-declined')}
                            fontSize='9px'
                            color='#a47110'
                            width='24px'
                            margin='0 0 0 3px'
                        >
                            {`DEC`}
                        </Badge>
                    )}
                    {showResetProviderValue && (
                        <ResetIcon
                            title={t('info.reset-bid.confirmation')}
                            onClick={resetProviderValue}
                        />
                    )}
                    {providerValue.currentAction === ProviderValueAction.show_unclassified && (
                        <ResetIcon
                            title={t('info.cancel-declassify')}
                            onClick={undoWinnerUnclassifiedProviderValue}
                        />
                    )}
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default ProviderValueItemAuctioneer;
