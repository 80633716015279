import { FC, useEffect } from 'react';
import { AuctionLotItemProps } from './props';
import Wrapper from '../../../../Common/wrapper';
import Actions from '../Actions';
import ApiService from '../../../../../services/api';
import { pad } from '../../../../../helpers/pad';
import ConditionalTimer from '../../../../ConditionalTimer';
import AuctioneerBestBid from '../AuctioneerBestBid';
import { Lot, ProposalCount, LotItem } from './styled';
import { useTranslation } from 'react-i18next';
import ValueReferenceDiff from '../ValueReferenceDiff';
import { Checkbox } from 'antd';
import { SelectLotMode, useLotsContext } from '../../../../../pages/Initial/select-lots.context';
import { StageLot } from '../../../../../Interfaces/stageLot';
import { addNotification } from '../../../../../helpers/notification';

const AuctionLotItemAuctionner: FC<AuctionLotItemProps> = ({
    auctionLot,
    selected,
    onSelectAuctionLot,
    changeStage,
    onBestBidLoaded,
    onFinishProviderPeriodConvoked,
    authUser,
    auctionNotice,
    closeBids,
    serverTimestamp,
    providerValues,
}) => {
    const { t } = useTranslation();
    const { selectedLots, setSelectedLots, bulkChangesMode, selectedMode } = useLotsContext();

    useEffect(() => {
        getBestBid();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionLot.id]);

    const getBestBid = async () => {
        const bestBidResponse = await ApiService.getBestBid(
            auctionLot.auctionNoticeId,
            auctionLot.id
        );

        if (!bestBidResponse) {
            onBestBidLoaded({
                lotId: auctionLot.id,
                value: null,
            });
        }

        onBestBidLoaded(bestBidResponse);
    };

    const replacedLotName = `${t('term.lot.title')} ${pad(auctionLot.item)}`;
    const isCota = `${auctionLot.quotaId !== null ? ` (${t('term.quota')})` : ''}`;

    const onSelectLotToAction = (event) => {
        const checked = event.target.checked;
        const selectedLotsSet = new Set(selectedLots);

        if (checked) {
            selectedLotsSet.add(auctionLot.id);
        } else {
            selectedLotsSet.delete(auctionLot.id);
        }

        const newSelectedLots = Array.from(selectedLotsSet);

        if (newSelectedLots.length > 10) {
            return addNotification(
                'Limite atingido',
                'Você só pode selecionar até 10 lotes.',
                'warning',
                3000
            );
        }

        setSelectedLots(newSelectedLots);
    };

    const getCanShowCheckboxSelect = () => {
        switch (selectedMode) {
            case SelectLotMode.start:
                return auctionLot.stage === StageLot.unStarted;

            case SelectLotMode.cancel:
                return [
                    StageLot.convoked,
                    StageLot.awaiting_call_provider,
                    StageLot.finished,
                    StageLot.awaiting_rejudge,
                    StageLot.started,
                    StageLot.negotiation,
                    StageLot.random_close_period_ended,
                    StageLot.close_period,
                    StageLot.close_period_ended,
                    StageLot.second_close_period,
                    StageLot.second_close_period_ended,
                    StageLot.repeat_second_close_period,
                    StageLot.repeat_second_close_period_ended,
                    StageLot.paused,
                    StageLot.random_close_period,
                    StageLot.random_period_ended,
                    StageLot.random_period,
                ].includes(auctionLot.stage);

            case SelectLotMode.advance:
                return [
                    StageLot.timeEnded,
                    StageLot.awaiting_call_provider,
                    StageLot.finished,
                    StageLot.awaiting_rejudge,
                    StageLot.random_close_period_ended,
                    StageLot.close_period_ended,
                    StageLot.second_close_period_ended,
                    StageLot.awaiting_repeat_close_period,
                    StageLot.repeat_second_close_period_ended,
                    StageLot.random_period_ended,
                ].includes(auctionLot.stage);

            case SelectLotMode.finishNegotiation:
                return auctionLot.stage === StageLot.negotiation;

            default:
                return false;
        }
    };

    return (
        <Lot
            position='relative'
            padding='5px 10px'
            borderRight={selected ? '1px solid #fff' : '1px solid #D6D6D6'}
            flexBox
            justifyContent='center'
            cursor='pointer'
            bgcolor={selected ? '#FFF' : '#f5f5f5'}
            onClick={() => onSelectAuctionLot(auctionLot)}
            height='86px'
        >
            {bulkChangesMode && getCanShowCheckboxSelect() && (
                <Wrapper
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '20px',
                        height: '100%',
                        zIndex: '1',
                        right: '0',
                        display: 'flex',
                        backgroundColor: selected ? '#FFF' : '#f5f5f5',
                    }}
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <Checkbox
                        id={`checkbox-lot:${auctionLot.id}`}
                        checked={!!selectedLots.find((lotId) => lotId === auctionLot.id)}
                        onChange={onSelectLotToAction}
                        style={{
                            position: 'absolute',
                            top: '32px',
                            left: '12px',
                            width: '100%',
                            height: '100%',
                            zIndex: '1',
                        }}
                    />
                </Wrapper>
            )}
            <Wrapper flexBox alignItems='center'>
                <Wrapper margin='0 10px 0 0'>
                    <LotItem title={`${replacedLotName}${isCota}`}>
                        <span>{replacedLotName}</span>
                        <span>{isCota}</span>
                    </LotItem>
                    <ProposalCount>
                        <span>
                            <b>
                                {!!auctionLot.proposalCount ? pad(auctionLot.proposalCount) : '--'}
                            </b>{' '}
                            {auctionLot?.proposalCount === 1
                                ? t('term.proposal')
                                : t('term.proposals')}
                        </span>
                    </ProposalCount>
                    <ValueReferenceDiff auctionLot={auctionLot} auctionNotice={auctionNotice} />
                </Wrapper>
            </Wrapper>
            <AuctioneerBestBid auctionLot={auctionLot} auctionNotice={auctionNotice} />
            <Wrapper
                width='100px'
                flexBox
                justifyContent='center'
                alignItems='center'
                margin='0 8px'
            >
                <ConditionalTimer
                    key={`timer:${auctionLot.id}`}
                    auctionLot={auctionLot}
                    auctionNotice={auctionNotice}
                    changeStage={changeStage}
                    onFinishProviderPeriodConvoked={onFinishProviderPeriodConvoked}
                    onFinishTimerLot={() => {}}
                    authUser={authUser}
                    serverTimestamp={serverTimestamp}
                    bidPeriodCloseMode={true}
                />
            </Wrapper>
            <Actions
                closeBids={closeBids}
                providerValues={providerValues}
                auctionLot={auctionLot}
                serverTimestamp={serverTimestamp}
                auctionNotice={auctionNotice}
                changeStage={changeStage}
                authUser={authUser}
            />
        </Lot>
    );
};

export default AuctionLotItemAuctionner;
