import React, { FC, ReactElement, useState } from 'react';
import ModalDeclassifyWinnerView from './ModalDeclassifyWinnerView';

export enum DeclassifyOption {
    'IRREMEDIABLE_VICES' = 1,
    'TECHNICAL_SPECIFICATIONS' = 2,
    'UNFEASIBLE_PRICES' = 3,
    'ABOVE_ESTIMATED_BUDGET' = 4,
    'DEMONSTRATED_FEASIBILITY' = 5,
    'NO_COMPLIENCE' = 6,
    'OTHERS' = 7,
}

export const declassifyOptions: Record<DeclassifyOption, string> = {
    [DeclassifyOption.IRREMEDIABLE_VICES]: 'Proposta contém vícios insanáveis.',
    [DeclassifyOption.TECHNICAL_SPECIFICATIONS]:
        'Proposta não obedece às especificações técnicas do instrumento convocatório.',
    [DeclassifyOption.UNFEASIBLE_PRICES]: 'Proposta apresenta preços inexequíveis.',
    [DeclassifyOption.ABOVE_ESTIMATED_BUDGET]: 'Proposta acima do orçamento estimado.',
    [DeclassifyOption.DEMONSTRATED_FEASIBILITY]:
        'Proposta não teve sua exequibilidade demonstrada',
    [DeclassifyOption.NO_COMPLIENCE]:
        'Proposta apresenta desconformidade com as exigências do instrumento convocatório.',
    [DeclassifyOption.OTHERS]: 'Outros.',
};

interface ModalDeclassifyWinnerProps {
    onConfirm: (justification: string) => Promise<void>;
    actionButton: ReactElement<any>;
    actionEvent?: 'onConfirm' | 'onClick';
    lotItem?: number;
    providerNumber?: number;
}

const ModalDeclassifyWinner: FC<ModalDeclassifyWinnerProps> = ({
    onConfirm,
    lotItem,
    providerNumber,
    actionButton,
    actionEvent = 'onConfirm',
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [justification, setJustification] = useState<string>('');
    const [declassifyOption, setDeclassifyOption] = useState<DeclassifyOption>();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setJustification('');
        setDeclassifyOption(undefined);
    };

    const handeOnConfirm = async () => {
        if (!declassifyOption) return;

        try {
            setLoading(true);

            const text =
                declassifyOption === DeclassifyOption.OTHERS
                    ? justification?.trim()
                    : declassifyOptions[declassifyOption];

            await onConfirm(text ?? '');
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    const hasJustification = declassifyOption !== DeclassifyOption.OTHERS || justification?.trim()?.length > 4

    const cloneActionButton = React.cloneElement(actionButton as ReactElement<any>, {
        [actionEvent]: () => {
            showModal();
        },
    });

    return (
        <ModalDeclassifyWinnerView
            visible={isModalVisible}
            actionButton={cloneActionButton}
            loading={loading}
            disabled={!declassifyOption || !hasJustification || loading}
            justification={justification}
            onChangeJustification={(e) => setJustification(e?.currentTarget?.value ?? '')}
            option={declassifyOption}
            onSelectOption={(e) => setDeclassifyOption(Number(e.target.value))}
            onCancel={closeModal}
            onConfirm={handeOnConfirm}
            lotItem={lotItem}
            providerNumber={providerNumber}
        />
    );
};

export default ModalDeclassifyWinner;
