import React, { FC, useState, ReactElement } from 'react';
import Wrapper from '../wrapper';
import { Button } from 'antd';
import { ConfirmationPopoverProps } from './props';
import Popover from 'react-tiny-popover';
import { ColorType, getColor } from '../theme';
import styled from 'styled-components';
import ClickOutside from '../ClickOutside';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const SendButton = styled(Button)`
    background: ${({theme}) => theme.colors.primary} !important;
    width: 60px;

    span {
        color: #fff;
    }
    :hover {
        background: ${({theme}) => theme.colors.primary};
    }
`;

const RejectButton = styled(Button)`
    background: #676160 !important;
    width: 60px;

    span {
        color: #fff;
    }
    :hover {
        background: #676160;
    }
`;

const Content: FC<any> = ({
    render,
    setOpenModal,
    onConfirm,
    maxWidth,
    onClose,
    id,
    omitConfirmButton,
    onReject,
}) => {
    const { t } = useTranslation();

    return (
        <ClickOutside onClickOutside={onClose}>
            <Wrapper
                id={id}
                bgcolor='#FFF'
                maxWidth={maxWidth}
                width='auto'
                minWidth='240px'
                height='auto'
                padding='13px'
            >
                <Wrapper fontSize='14px' padding='3px 0px 10px 0px'>
                    {render}
                </Wrapper>
                <Wrapper flexBox justifyContent='space-between' alignItems='center'>
                    <Button
                        style={{
                            borderRadius: '3px',
                            fontSize: '13px',
                            width: '76px',
                            textTransform: 'capitalize',
                        }}
                        onClick={() => setOpenModal(false)}
                    >
                        {t('term.close')}
                    </Button>
                    {onReject && (
                        <RejectButton
                            style={{
                                borderRadius: '3px',
                                fontSize: '13px',
                            }}
                            onClick={() => onReject()}
                        >
                            {t('term.no')}
                        </RejectButton>
                    )}
                    {!omitConfirmButton && (
                        <SendButton
                            style={{ borderRadius: '3px', fontSize: '13px' }}
                            onClick={() => onConfirm()}
                        >
                            {t('term.yes')}
                        </SendButton>
                    )}
                </Wrapper>
            </Wrapper>
        </ClickOutside>
    );
};

const ConfirmationPopover: FC<ConfirmationPopoverProps> = ({
    children,
    onConfirm,
    render,
    opened,
    maxWidth,
    disabled,
    id,
    omitConfirmButton,
    onReject,
}) => {
    const [modalOpened, setOpenModal] = useState<boolean>(opened || false);
    const cloneChildren = React.cloneElement(children as ReactElement<any>, {
        onClick: (ev: any) => !disabled && handleClick(ev),
    });

    const handleClick = (ev: any) => {
        ev.stopPropagation();
        setOpenModal(!modalOpened);
    };

    return (
        <Popover
            isOpen={modalOpened}
            key={v4()}
            position={['bottom', 'right', 'left']}
            content={() => (
                <Content
                    id={id}
                    maxWidth={maxWidth}
                    onConfirm={() => {
                        onConfirm?.();
                        setOpenModal(false);
                    }}
                    setOpenModal={(ev: any) => setOpenModal(ev)}
                    render={render}
                    onClose={handleClick}
                    omitConfirmButton={omitConfirmButton}
                    onReject={onReject}
                />
            )}
        >
            {cloneChildren}
        </Popover>
    );
};

export default ConfirmationPopover;
