import { FC, useState } from 'react';
import Wrapper from '../Common/wrapper';
import { MdOutlineExitToApp } from 'react-icons/md';
import { ModalDeclineProviderProps } from './props';
import { Button, Form, Modal, Radio, Space } from 'antd';
import ApiService from '../../services/api';
import { DeclineType } from '../../Interfaces/decline';
import { CreateDecline } from '../../services/api/interfaces';
import { addNotification } from '../../helpers/notification';
import { useTranslation } from 'react-i18next';
import { pad } from '../../helpers/pad';
import ConfirmationPopover from '../Common/ConfirmationPopover';

const ModalDeclineProvider: FC<ModalDeclineProviderProps> = ({ auctionId, auctionLot }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [declineType, setDeclineType] = useState(DeclineType.temporary);
    const [declineOption, setDeclineOption] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setDeclineType(DeclineType.temporary)
        setIsModalVisible(false);
        setDeclineOption(undefined)
        setLoading(false)
    }

    const { t } = useTranslation();

    const createDecline = async () => {
        setLoading(true);

        const data: CreateDecline = {
            auctionNoticeId: auctionId,
            option: Number(declineOption),
            type: declineType,
        };

        if (declineType === DeclineType.temporary) {
            data.lotId = auctionLot.id;
        }

        let error: any;
        await ApiService.createDecline(data, (err) => {
            error = err;
        });

        if (!error) {
            hideModal()
            return addNotification(t('term.success'), t('info.decline.requested'), 'success', 3000);
        }
        
        setLoading(false);
    };

    const replacedLotName = `${t('term.lot.title')} ${pad(auctionLot.item)}`;

    const declineOptions: { [key: number]: string } = {
        1: 'Lancei a proposta errada na plataforma',
        2: 'Meu valor ofertado está inexequível',
        3: 'O valor de referência do(s) lote(s) é inexequível',
        4: 'Não terei mais condições de entregar o bem ou serviço licitado',
        5: 'Os preços ofertados pelos demais concorrentes estão manifestamente inexequíveis',
    };

    const onChange = (ev) => {
        setDeclineOption(ev.target.value);
    };

    return (
        <>
            {isModalVisible && (
                <Modal
                    title={t('info.request.decline.lot')}
                    visible={isModalVisible}
                    width='400px'
                    onCancel={hideModal}
                    footer={
                        <Wrapper flexBox justifyContent='flex-end'>
                            <Button onClick={hideModal} disabled={loading}>
                                {t('info.cancel')}
                            </Button>
                            <ConfirmationPopover
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.decline-send')}</p>
                                    </Wrapper>
                                }
                                maxWidth='230px'
                                onConfirm={createDecline}
                                disabled={loading}
                            >
                                <Button disabled={!declineOption || loading} type='primary' loading={loading}>
                                    {t('term.confirm')}
                                </Button>
                            </ConfirmationPopover>
                        </Wrapper>
                    }
                >
                    <Wrapper>
                        <Radio.Group
                            onChange={(ev) => {
                                setDeclineType(ev.target.value);
                            }}
                            value={declineType}
                        >
                            <Space direction='vertical'>
                                <Radio value={DeclineType.temporary}>
                                    {t('info.provider-request.decline.lot')}
                                    <b>{replacedLotName}</b>
                                </Radio>
                                <Radio value={DeclineType.definitive}>
                                    {t('info.confirm.definitive-decline')}
                                </Radio>
                            </Space>
                        </Radio.Group>
                        <Wrapper margin='20px 0 0 0'>
                            <Form.Item label={`${t('term.reason')}:`}>
                                <Radio.Group onChange={onChange} value={declineOption}>
                                    <Space direction='vertical'>
                                        {Object.keys(declineOptions).map((key) => {
                                            const text = declineOptions[key];
                                            return <Radio value={key}>{text}</Radio>;
                                        })}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Wrapper>
                    </Wrapper>
                </Modal>
            )}
            <ConfirmationPopover
                render={
                    <Wrapper fontSize='13px'>
                        <p>{t('info.decline-confirm')}</p>
                    </Wrapper>
                }
                maxWidth='230px'
                onConfirm={showModal}
            >
                <Button
                    title={t('info.request.decline.lot')}
                    type='primary'
                    shape='round'
                    style={{
                        background: '#992e2e',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    icon={
                        <MdOutlineExitToApp
                            style={{
                                fontSize: '18px',
                            }}
                        />
                    }
                    size='small'
                />
            </ConfirmationPopover>
        </>
    );
};

export default ModalDeclineProvider;
