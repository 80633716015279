export interface FeatureFlags {
    envioDeMensagemVencimentosDeSolicitacao: boolean;
    generatedDocuments: boolean;
    gerenciarRegrasEModalidades: boolean;
    integracaoDiretriz: boolean;
    integrationPncp: boolean;
    novaTelaDetalhesPesquisaProcesso: boolean;
    novaTelaPesquisa: boolean;
    novaTelaProcessos: boolean;
    verHabilitacaoNaDisputa: boolean;
    solicitacaoDocumentoComplementarDisputa: boolean;
    habilitarNovoJulgamento: boolean;
}

export interface User {
    providerId?: number;
    userId: number;
    providerAuctionCode: number | undefined | null;
    typeAccess: TypeAccess;
    featureFlags?: FeatureFlags
}

export enum TypeAccess {
    'admin' = 'admin',
    'provider' = 'provider',
    'organization' = 'organization',
    'management' = 'management',
    'citizen' = 'citizen',
}

export enum UserType {
    supplier = 'supplier',
    auctioneer = 'auctioneer',
    citizen = 'citizen',
}

export enum UserLanguages {
    en = 'en',
    'pt-BR' = 'pt-BR',
}
