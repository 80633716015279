import { FC, useEffect, useState, useRef, useCallback } from 'react';
import { ProviderValuesProps } from './props';
import { Pagination } from 'antd';
import Wrapper from '../Common/wrapper';
import Header from '../Common/Header';
import Scroll from '../Common/Scroll';
import ProviderValueItemAuctioneer from './components/ProviderValueItemAuctioneer';
import ProviderValueItemSupplier from './components/ProviderValueItemSuplier';
import { ProviderValue, ProviderValueStatus } from '../../Interfaces/providerValues';
import { StageLot } from '../../Interfaces/stageLot';
import ApiService from '../../services/api';
import { SocketEvents, SocketEvent } from '../../Interfaces/socketEvents';
import TinyQueue from 'tinyqueue';
import { Spin, SpinArea } from '../Common/Spin';
import ConfirmationPopover from 'Components/Common/ConfirmationPopover';
import { DisableIcon } from './styles';
import { addNotification } from 'helpers/notification';
import { timeout } from 'helpers/timer';
import { getProviderValuesActions } from './provider-values.service';
import { isAuctioneer, isCitizen, isProvider } from '../../helpers/permissions';
import ProviderValueItemCitzen from './components/ProviderValueItemCitzen';
import { useTranslation } from 'react-i18next';
import BidHistory from '../BidHistory';
import { debounce } from 'lodash';
import { PaginationState } from 'Interfaces/pagination';
import ModalDeclassifyWinner from 'Components/AuctionLots/components/ModalDeclassifyWinner';

const LIMIT_PAGINATION = 10;

const initialPaginationState: PaginationState = {
    skip: 0,
    limit: LIMIT_PAGINATION,
}

const ProviderValues: FC<ProviderValuesProps> = ({
    auctionNoticeLotSelected,
    authUser,
    auctionNotice,
    serverTimestamp,
    socketConnection,
}) => {
    const [loading, setLoading] = useState(true);
    const [isOpened, setOpened] = useState(true);
    const [providerValues, setProviderValues] = useState<ProviderValue[]>([]);
    const [pagination, setPagination] = useState<PaginationState>(initialPaginationState);
    const [providerValuesCount, setProviderValuesCount] = useState<number>(0);
    const { t } = useTranslation();

    let queueLock = false;

    const providerValuesRef: any = useRef(null);
    providerValuesRef.current = { providerValues, setProviderValues };

    const auctionNoticeLotSelectedRef: any = useRef(null);
    auctionNoticeLotSelectedRef.current = { auctionNoticeLotSelected };

    const queueEvents: any = new TinyQueue([]);

    useEffect(() => {
        if (auctionNoticeLotSelected.id) {
            setPagination({...initialPaginationState});
        }
    }, [auctionNoticeLotSelected.id]);
    
    useEffect(() => {
        getProviderValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    useEffect(() => {
        replaceConvokedProvider("useEffect convokedProvider");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionNoticeLotSelected.convokedProvider]);

    useEffect(() => {
        if (
            auctionNoticeLotSelected.stage === StageLot.negotiation ||
            auctionNoticeLotSelected.stage === StageLot.negotiation_finished
        ) {
            replaceConvokedProvider("useEffect auctionNoticeLotSelected.stage");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionNoticeLotSelected.stage]);

    useEffect(() => {
        handleSocketEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const handleSocketEvents = () => {
        if (!socketConnection) {
            return;
        }
        socketConnection.on(SocketEvents.providerValueUpdated, (event: any) =>
            receiveEvent({
                type: SocketEvents.providerValueUpdated,
                message: event,
            })
        );

        socketConnection.on(SocketEvents.providerValuesCreated, (event: any) =>
            receiveEvent({
                type: SocketEvents.providerValuesCreated,
                message: event,
            })
        );

        socketConnection.on(SocketEvents.providerValuesUpdated, (event: any) =>
            receiveEvent({
                type: SocketEvents.providerValuesUpdated,
                message: event,
            })
        );

        socketConnection.on(SocketEvents.providerValueReseted, (event: any) =>
            receiveEvent({
                type: SocketEvents.providerValueUpdated,
                message: event,
            })
        );
    };

    const replaceConvokedProvider = (text?: string) => {
        const { providerValues } = providerValuesRef.current;

        replaceProviderValues(providerValues, text);
    };

    const handleProviderValueUpdated = (event: ProviderValue) => {
        if (event?.lotId !== auctionNoticeLotSelectedRef.current.auctionNoticeLotSelected.id) {
            return;
        }

        const providerRef = providerValuesRef.current.providerValues;
        const providerIndex = providerRef.findIndex(
            (providerValue: ProviderValue) => providerValue.id === event.id
        );

        providerRef[providerIndex] = {
            ...providerRef[providerIndex],
            ...event,
            value: event.value,
        };

        replaceProviderValues(providerRef, "handleProviderValueUpdated");
    };

    const handleProviderValueCreated = (event: any) => {
        const lotId = event[0]?.lotId;
        if (lotId !== auctionNoticeLotSelectedRef.current.auctionNoticeLotSelected.id) {
            return;
        }
        const providerRef: ProviderValue[] = providerValuesRef.current.providerValues

        const newProviderValues = (event as ProviderValue[])?.filter(eventProviderValue => (
            providerRef !== undefined &&
            providerRef.findIndex(
                (providerValue: ProviderValue) => providerValue.id === eventProviderValue.id
            ) !== -1
        ))

        replaceProviderValues(newProviderValues, "handleProviderValueCreated");
    };

    const getProviderValues = async () => {
        setLoading(true);
        const { auctionNoticeId, id } = auctionNoticeLotSelected;

        const {providerValues, count} = await ApiService.getPaginatedProviderValues(auctionNoticeId, id, pagination);

        replaceProviderValues(providerValues || [], "getProviderValues");
        setProviderValuesCount(count)
        timeout(() => setLoading(false), 100);
    };

    const replaceProviderValues = (providerValues: ProviderValue[], str?: string) => {
        const newProviderValues = (providerValues = getProviderValuesActions(
            providerValues,
            auctionNotice.judgmentCriterion,
            auctionNoticeLotSelectedRef.current.auctionNoticeLotSelected,
            serverTimestamp
        ));

        newProviderValues.forEach((providerValue: ProviderValue) => {
            providerValue.isAuthProviderBid = false;

            if (providerValue.providerAuctionCode === authUser.providerAuctionCode) {
                providerValue.isAuthProviderBid = true;
            }
        });

        providerValuesRef.current.setProviderValues(newProviderValues);
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();
                const { type, message } = event;

                switch (type) {
                    case SocketEvents.providerValueUpdated:
                    case SocketEvents.providerValueWinner:
                        return handleProviderValueUpdated(message);

                    case SocketEvents.providerValuesCreated:
                    case SocketEvents.providerValuesUpdated:
                        return handleProviderValueCreated(message);
                }

                await new Promise((r) => timeout(r, 20));
            }
        } finally {
            queueLock = false;
        }
    };

    const getWinnerProviderValue = async () => {
        const { auctionNoticeId, id } = auctionNoticeLotSelected;

        const providerValueList = await ApiService.getProviderValues(auctionNoticeId, id);
        return providerValueList?.find(
            (providerValue) => providerValue.status === ProviderValueStatus.winner
        );
    };

    const desclassify = async (justification: string) => {
        const winner = await getWinnerProviderValue();

        if (!winner) {
            return addNotification(
                t('info.declassify.proposa.error.title'),
                t('info.declassify.proposa.error.message'),
                'warning',
                3000
            );
        }

        await ApiService.desclassifyProviderValue({
            auctionNoticeId: auctionNoticeLotSelected.auctionNoticeId,
            auctionLotId: auctionNoticeLotSelected.id,
            providerValueId: winner.id,
            justification
        });
    };

    const getRenderProviderValue = () => {
        if (loading) {
            return (
                <SpinArea>
                    <Spin />
                </SpinArea>
            );
        }

        if (!providerValuesRef.current.providerValues?.length) {
            const message = t('info.empty-bids') === 'info.empty-bids' ? 'Ainda não existe lances válidos!' : t('info.empty-bids');
            return (
                <Wrapper margin='30px 0' flexBox justifyContent='center'>
                    {message} 
                </Wrapper>
            );
        }
        return providerValuesRef.current.providerValues
            .slice(0, LIMIT_PAGINATION)
            .map((providerValue: ProviderValue, i: number) => {
                if (isAuctioneer(authUser)) {
                    return (
                        <ProviderValueItemAuctioneer
                            key={providerValue.id}
                            index={i + pagination.skip}
                            auctionNotice={auctionNotice}
                            providerValue={providerValue}
                            auctionNoticeId={auctionNoticeLotSelected.auctionNoticeId}
                            auctionLot={auctionNoticeLotSelected}
                        />
                    );
                }

                if (isProvider(authUser)) {
                    return (
                        <ProviderValueItemSupplier
                            key={providerValue.id}
                            index={i}
                            authUser={authUser}
                            auctionNotice={auctionNotice}
                            providerValue={providerValue}
                        />
                    );
                }

                if (isCitizen(authUser)) {
                    return (
                        <ProviderValueItemCitzen
                            key={providerValue.id}
                            index={i}
                            auctionNotice={auctionNotice}
                            providerValue={providerValue}
                        />
                    );
                }

                return <></>;
            });
    };

    const handlePaginate = useCallback(
        debounce((page: number) => {
            setPagination((prevState) => ({
                ...prevState,
                skip: prevState.limit * (page - 1),
            }));
        }, 400),
        []
    );

    const winner: ProviderValue = providerValuesRef?.current?.providerValues?.find(
        (providerValue: ProviderValue) => providerValue.status === ProviderValueStatus.winner
    );

    const visibleDeclassifyWinner =
        (auctionNoticeLotSelected.stage === StageLot.negotiation_finished ||
            auctionNoticeLotSelected.stage === StageLot.negotiation) &&
        isAuctioneer(authUser);

    return (
        <Wrapper
            width='100%'
            minWidth='290px'
            margin='10px 0'
            border='1px solid rgba(204, 204, 204, 0.5);'
            minHeight={isOpened ? '100px' : 'auto'}
            height={isOpened ? 'auto' : '46px'}
        >
            <Header
                showMinimize={true}
                icon='price'
                title={t('info.best.bids')}
                onExpand={() => setOpened(!isOpened)}
                expandDirection={isOpened ? 'rotate(-90deg)' : 'rotate(0deg)'}
                customActions={
                    <>
                        <BidHistory
                            auctionNoticeLotSelected={auctionNoticeLotSelected}
                            auctionNotice={auctionNotice}
                        />
                        {visibleDeclassifyWinner && (
                            <Wrapper flexBox alignItems='center' margin='0 0 0 5px'>
                                <ModalDeclassifyWinner
                                    onConfirm={desclassify}
                                    actionButton={
                                        <ConfirmationPopover
                                            maxWidth='230px'
                                            render={
                                                <Wrapper fontSize='13px'>
                                                    <p>
                                                        {t('info.declassify.winner.confirmation')}
                                                    </p>
                                                </Wrapper>
                                            }
                                        >
                                            <DisableIcon title={t('info.declassify.winner')} />
                                        </ConfirmationPopover>
                                    }
                                    lotItem={auctionNoticeLotSelected?.item}
                                    providerNumber={winner?.providerAuctionCode}
                                />
                            </Wrapper>
                        )}
                    </>
                }
            />

            {isOpened && (
                <>
                    <Scroll
                        style={{
                            overflowY: 'auto',
                            height: 'calc(100% - 46px)',
                        }}
                    >
                        {getRenderProviderValue()}
                    </Scroll>
                    {providerValuesCount > LIMIT_PAGINATION && (
                        <Wrapper margin='15px 0' flexBox justifyContent='center' alignItems='center'>
                            <Pagination
                                current={Math.floor(pagination.skip / pagination.limit) + 1 ?? 1}
                                defaultCurrent={Math.floor(pagination.skip / pagination.limit) + 1 ?? 1}
                                showSizeChanger={false}
                                disabled={loading}
                                total={providerValuesCount}
                                onChange={(page) => {
                                    handlePaginate(page);
                                }}
                            />
                        </Wrapper>
                    )}
                </>
            )}
        </Wrapper>
    );
};

export default ProviderValues;
