export interface AuctionTypeRuleGeneralSettings {
    etapas: {
        permitirReabrirFases?: boolean;
    };
    cadastro: {
        modoAberto?: boolean;
        modoAbertoFechado?: boolean;
        modoFechado?: boolean;
        modoFechadoAberto?: boolean;
        modoNaoSeAplica?: boolean;
        criterioMenorPreco?: boolean;
        criterioMenorPrecoTaxa?: boolean;
        criterioMaiorDesconto?: boolean;
        criterioMaiorPreco?: boolean;
        criterioMaiorRetornoEconomico?: boolean;
        criterioConteudoArtistico?: boolean;
        criterioMelhorTecnica?: boolean;
        criterioMelhorTecnicaEPreco?: boolean;
        criterioNaoSeAplica?: boolean;
        aplicarLei123?: boolean;
        casasDecimais_2?: boolean;
        casasDecimais_3?: boolean;
        casasDecimais_4?: boolean;
        permitirRandomico?: string;
        minimoPrazoDisputa?: string;
        intervaloEntreLances?: string;
        antecedenciaDisputa?: string;
        numeroProcessoAutomatico?: boolean;
        formulaNumeroAutomatico?: string;
        numeroModalidadeAutomatico?: boolean;
        formulaNumeroModalidadeAutomatico?: string;
        sigilosidadeDosLotes?: number;
        exigirAprovacaoPublicar?: number;
        ocultarQuantitativoDoLote?: boolean;
        permitirDespesasAdicionais?: string;
        permitirMultiplosVencedores?: string;
        permitirPublicarSemSegmento?: boolean;
        manterSigiloExternoAte?: string;
        desativarOpcaoDeclinio?: boolean;
    };
    comissao: {
        executivo: {
            cancela?: boolean;
            publica?: boolean;
            recurso?: boolean;
            adjudica?: boolean;
            habilita?: boolean;
            homologa?: boolean;
            descricao?: string;
            classifica?: boolean;
            salvaRascunho?: boolean;
            gerenciaSessao?: boolean;
            declaraVencedor?: boolean;
        };
        equipeApoio: {
            cancela: boolean;
            publica: boolean;
            recurso: boolean;
            adjudica: boolean;
            habilita: boolean;
            homologa: boolean;
            descricao: string;
            classifica: boolean;
            salvaRascunho: boolean;
            gerenciaSessao: boolean;
            declaraVencedor: boolean;
        };
        operacional: {
            cancela: boolean;
            publica: boolean;
            recurso: boolean;
            adjudica: boolean;
            habilita: boolean;
            homologa: boolean;
            descricao: string;
            classifica: boolean;
            salvaRascunho: boolean;
            gerenciaSessao: boolean;
            declaraVencedor: boolean;
        };
    };
    processo: {
        sequenciaFases?: {
            normal?: boolean;
            invertida?: boolean;
        };
        habilitarGerenciamentoDeSessao?: boolean;
    };
    solicitacoes: {
        recurso: {
            habilitar?: boolean;
            diasRecurso?: string;
            horarioFinal?: string;
            diasContrarrazao?: string;
            minutosIntencaoRecurso?: string;
            permiteRedefinirPrazos?: boolean;
        };
        impugnacao: {
            diasUteis?: string;
            habilitar?: boolean;
        };
        esclarecimentos: {
            diasUteis?: string;
            habilitar?: boolean;
        };
    };
    disputa: {
        permitirContraPropostaMultiplosVencedores?: boolean
    }
}


export interface AuctionTypeRule extends Record<string, any> {
    id: string;
    auctionType: string;
    organizationId: number | null;
    description: string;
    generalSettings: AuctionTypeRuleGeneralSettings;
    actived: number;
}


export interface AuctionNotice {
    id: number;
    auctionNumber: string;
    accreditationNumber: string;
    methodDispute: number;
    judgmentCriterion: 1 | 2 | 3;
    rangeSupplierParticipation: number;
    rangeRegionParticipation: number;
    organizationUnit: {
        organizationUnitName: string;
    };
    organization: {
        organizationName: string;
    };
    decimalPlaces: number;
    typeValueBid: TypeValueBidTypes;
    rateTypeBid: number;
    auctionType: AuctionNoticeType;
    disputeDuration: number;
    isRandomTime: number;
    deadlineIntentAppeal: string;
    appealStatus: AppealStatusType;
    isPhaseReversal: number;
    auctionTypeRules: AuctionTypeRule;
}

export enum AuctionNoticeType {
    E = 'E',
    D = 'D',
    L = 'L',
}

export enum TypeValueBidTypes {
    total = 'total',
    unit = 'unit',
    kit = 'kit',
}

export enum AppealStatusType {
    closed = 'closed',
    intent = 'intent',
    request = 'request',
    counterReason = 'counterReason'
}
