import React, { FC } from 'react';
import { Button, Form, Modal, Radio, Space, Input, RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';
import Wrapper from 'Components/Common/wrapper';
import { DeclassifyOption, declassifyOptions } from './ModalDeclassifyWinner';
import { pad } from 'lodash';

interface ModalDeclassifyWinnerViewProps {
    option?: DeclassifyOption;
    onSelectOption?: (e: RadioChangeEvent) => void;
    justification?: string;
    onChangeJustification?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    actionButton: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    visible: boolean;
    loading?: boolean;
    disabled?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    lotItem?: number;
    providerNumber?: number;
}

const ModalDeclassifyWinnerView: FC<ModalDeclassifyWinnerViewProps> = ({
    option,
    onSelectOption,
    justification,
    onChangeJustification,
    onCancel,
    onConfirm,
    actionButton,
    visible,
    lotItem,
    providerNumber,
    loading = false,
    disabled = false,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {visible && (
                <Modal
                    title={t('info.declassify.provider')}
                    width='400px'
                    visible={visible}
                    onCancel={onCancel}
                    footer={
                        <Wrapper flexBox justifyContent='flex-end'>
                            <Button onClick={onCancel} disabled={loading}>
                                {t('info.cancel')}
                            </Button>
                            <Button
                                disabled={disabled || loading}
                                type='primary'
                                loading={loading}
                                onClick={onConfirm}
                            >
                                {t('term.confirm')}
                            </Button>
                        </Wrapper>
                    }
                >
                    <Wrapper>
                        <Wrapper>
                            <p>
                                {t('term.declassify')}{' '}
                                <strong>
                                    {providerNumber
                                        ? `${t('term.provider')} ${pad(String(providerNumber))}`
                                        : t('term.providers')}
                                </strong>{' '}
                                {t('term.in')} <strong>{t('term.lot.title')} {lotItem}</strong>
                            </p>
                        </Wrapper>
                        <Wrapper margin='20px 0 0 0'>
                            <Form.Item label={<strong>{t('info.declassify.select-justification')}</strong>}>
                                <Radio.Group onChange={onSelectOption} value={option}>
                                    <Space direction='vertical'>
                                        {Object.keys(declassifyOptions).map((key) => {
                                            const text = declassifyOptions[key];
                                            return <Radio value={Number(key)}>{text}</Radio>;
                                        })}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            {option === DeclassifyOption.OTHERS && (
                                <Form.Item>
                                    <Input.TextArea
                                        required
                                        rows={3}
                                        placeholder={t('info.modal.message.placeholder.info')}
                                        value={justification}
                                        onChange={onChangeJustification}
                                    />
                                </Form.Item>
                            )}
                        </Wrapper>
                    </Wrapper>
                </Modal>
            )}
            {actionButton}
        </>
    );
};

export default ModalDeclassifyWinnerView;
